<template>
  <Dialog :common-dialog="dialog" :dialog-width="dialogWidth">
    <template v-slot:title>
      Invoice Schedule ({{ invoiceVisitScheduleOutput.length }})
      {{ getDuration() }}
    </template>
    <template v-slot:body>
      <v-container>
        <table width="100%">
          <thead>
            <tr class="">
              <th
                class="font-size-16 font-weight-700 py-1 grey lighten-4 color-custom-blue"
              ></th>
              <th
                class="font-size-16 font-weight-700 py-1 grey lighten-4 color-custom-blue"
              >
                Invoice Date
              </th>
              <th
                class="font-size-16 font-weight-700 py-1 grey lighten-4 color-custom-blue"
              >
                Day
              </th>
              <th
                class="font-size-16 font-weight-700 py-1 grey lighten-4 color-custom-blue"
              >
                Due Date
              </th>
              <th
                class="font-size-16 font-weight-700 py-1 grey lighten-4 color-custom-blue"
              >
                Billing Amount (in SGD)
              </th>
            </tr>
          </thead>
          <tr
            v-for="(row, index) in invoiceVisitScheduleOutput"
            :key="index"
            :class="{
              'visit-row-hover': row.isEdited,
              'orange lighten-5': index % 2 !== 0,
            }"
          >
            <td
              class="font-size-14 font-weight-500"
              align="center"
              valign="middle"
            >
              {{ index + 1 }}.
            </td>
            <td>
              <DatePicker
                v-model="row.startDate"
                :disabled="visitId > 0 ? true : false"
                key="visit-show-schedule-start-date"
                id="visit-show-schedule-start-date"
                :min-date="todayDate"
                placeholder="Start Date"
                v-on:change="
                  updateDay($event, index);
                  row.isEdited = 1;
                "
              />
            </td>
            <td>
              <v-text-field
                readonly
                solo
                flat
                dense
                :disabled="visitId > 0 ? true : false"
                filled
                hide-details
                :value="row.daysOfWeek"
              ></v-text-field>
            </td>
            <td>
              <DatePicker
                :disabled="visitId > 0 ? true : false"
                v-model="row.dueDate"
                key="visit-show-schedule-due-date"
                id="visit-show-schedule-due-date"
                :min-date="row.dueDate"
                placeholder="Due Date"
              />
            </td>
            <td>
              <v-text-field
                :disabled="visitId > 0 ? true : false"
                key="visit-show-billing-amount-date"
                id="visit-show-billing-amount-date"
                placeholder="Billing Amount"
                type="number"
                solo
                flat
                dense
                filled
                readonly
                hide-details
                :value="row.billing_amount"
                v-on:keypress="limitDecimal($event, row.billing_amount)"
              ></v-text-field>
            </td>
          </tr>
        </table>
      </v-container>
    </template>
    <template v-slot:action>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button white--text"
        color="cyan"
        v-on:click="validateSchedule()"
      >
        Save
      </v-btn>
      <v-btn
        class="mx-2 custom-grey-border custom-bold-button"
        v-on:click="$emit('close', true)"
      >
        Close
      </v-btn>
    </template>
  </Dialog>
</template>

<script>
import { mapGetters } from "vuex";
import { toSafeInteger } from "lodash";
import DatePicker from "@/view/components/QDatePicker.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";
import { SET_INVOICE_RECURRING_DATE } from "@/core/services/store/invoice.module";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  name: "job-ctx-show-schedule",
  data() {
    return {
      todayDate: moment(new Date()).format("YYYY-MM-DD"),
      visitId: 0,
    };
  },
  props: {
    dialog: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    updateDay(date, index) {
      this.invoiceVisitScheduleOutput[index].daysOfWeek =
        moment(date).format("dddd");
    },
    getDuration() {
      //
    },
    validateSchedule() {
      this.$store.commit(
        SET_INVOICE_RECURRING_DATE,
        this.invoiceVisitScheduleOutput
      );
      this.$emit("next", true);
    },
  },
  components: {
    Dialog,
    DatePicker,
  },
  created() {
    this.visitId = Number(this.$route?.query?.visit ?? 0);
  },
  computed: {
    ...mapGetters(["invoiceVisitScheduleOutput", "vCalculations"]),
    dialogWidth() {
      return toSafeInteger((document.body.clientWidth / 100) * 70);
    },
  },
};
</script>
