<template>
  <v-row>
    <v-col md="8">
      <v-row class="mx-0">
        <v-col md="12">
          <label class="mb-1" for="visit-one-off-start-date"
            >Is Invoice Needed</label
          >
          <v-select
            id="payment-mode"
            :disabled="visitId > 0 ? true : false"
            dense
            v-model="recurringinvoice.invoice_needed"
            :items="neededInvoiceList"
            item-color="cyan"
            item-text="text"
            item-value="value"
            class="pl-0 mb-1"
            filled
            hide-details
            placeholder="Is Invoice Needed"
            solo
            flat
            color="cyan"
          />
        </v-col>
        <v-col md="12" v-if="recurringinvoice.invoice_needed">
          <v-select
            id="payment-mode"
            :disabled="visitId > 0 ? true : false"
            dense
            v-model="recurringinvoice.invoice_main_type"
            :items="invoiceMainTypeList"
            item-text="label"
            item-color="cyan"
            item-value="value"
            class="pl-0 mb-1"
            filled
            hide-details
            placeholder="Select Main Type"
            solo
            flat
            @change="
              updateRecurringInvoiveSchedule(
                'recurringinvoice.invoice_type',
                $event
              ),
                setDefaultInvoiceType($event)
            "
            color="cyan"
          />
          <RadioInputCustom
            v-if="false"
            v-model="recurringinvoice.invoice_main_type"
            row
            :items="[
              {
                label: 'Per Visit',
                color: 'red darken-3 white--text',
                value: 'per_visit',
              },
              {
                label: 'One invoice for all jobs',
                color: 'orange darken-4 white--text',
                value: 'invoice_fol_all',
              },
              {
                label: 'Fixed amount',
                color: 'light-green darken-3 white--text',
                value: 'fixed_amount',
              },
            ]"
            @change="
              updateRecurringInvoiveSchedule(
                'recurringinvoice.invoice_main_type',
                $event
              ),
                setDefaultInvoiceType($event)
            "
          />
        </v-col>
        <v-col md="4" v-if="recurringinvoice.invoice_needed">
          <label class="mb-1" for="visit-one-off-end-time"> Invoice Type</label>
          <v-select
            id="payment-mode"
            dense
            v-model="recurringinvoice.invoice_type"
            :items="newArry"
            :disabled="visitId > 0 ? true : false"
            item-text="text"
            item-color="cyan"
            item-value="value"
            class="pl-0 mb-1"
            filled
            hide-details
            placeholder="Select Invoice Type"
            solo
            flat
            @change="
              updateRecurringInvoiveSchedule(
                'recurringinvoice.invoice_type',
                $event
              )
            "
            color="cyan"
          />
        </v-col>
        <v-col md="4" v-if="recurringinvoice.invoice_needed">
          <label class="mb-1" for="visit-one-off-start-date">Payment due</label>
          <v-select
            id="payment-mode"
            dense
            v-model="recurringinvoice.payment_due"
            :items="paymentDueList"
            item-text="text"
            item-color="cyan"
            item-value="value"
            class="pl-0 mb-1"
            filled
            hide-details
            placeholder="Select Payment Due"
            :disabled="visitId > 0 ? true : false"
            solo
            flat
            color="cyan"
            @change="
              updateRecurringInvoiveSchedule(
                'recurringinvoice.payment_due',
                $event
              )
            "
          />
        </v-col>
        <v-col md="4" v-if="recurringinvoice.invoice_needed">
          <label class="mb-1" for="visit-one-off-start-time"
            >Send Invoice To Customer</label
          >
          <v-select
            id="payment-mode"
            dense
            v-model="recurringinvoice.send_invoice"
            :items="sendInvoiceList"
            item-text="text"
            item-color="cyan"
            item-value="value"
            class="pl-0 mb-1"
            filled
            hide-details
            placeholder="Select Payment Due"
            solo
            :disabled="visitId > 0 ? true : false"
            flat
            color="cyan"
            @change="
              updateRecurringInvoiveSchedule(
                'recurringinvoice.send_invoice',
                $event
              )
            "
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import RadioInputCustom from "@/view/components/RadioInputCustom.vue";
import { UPDATE_SCHEDULE_LIST } from "@/core/services/store/invoice.module";
import { UPDATE_INVOICE_SCHEDULE_RECURRING } from "@/core/services/store/invoice.module";
import moment from "moment-timezone";
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

import { mapGetters } from "vuex";
export default {
  name: "invoice-shedule",
  data() {
    return {
      paymentDueList: [
        { value: 2, text: "Net 15" },
        { value: 3, text: "Net 30" },
        { value: 4, text: "Net 45" },
      ],
      invoiceMainTypeList: [
        {
          label: "Per Visit",
          value: "per_visit",
        },
        {
          label: "One invoice for all jobs",
          value: "invoice_fol_all",
        },
        {
          label: "Fixed amount",
          value: "fixed_amount",
        },
      ],
      invoiceTypeListAll: [
        {
          value: "remind_me_job_closed",
          text: "Remind me to invoice when job is closed",
        },
        { value: "no_need_auto", text: "No auto invoice needed" },
        {
          value: "after_each_visit_complete",
          text: "After each visit is comleted",
        },
        {
          value: "full_set_of_job_created",
          text: "Once full set of job Created",
        },
        {
          value: "full_set_of_job_completed",
          text: "Once full set of job completed",
        },
      ],

      sendInvoiceList: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      neededInvoiceList: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      visitId: 0,
    };
  },
  methods: {
    setDefaultInvoiceType(value) {
      let invoiceType = "";
      if (value && value == "per_visit") {
        invoiceType = "no_need_auto";
      } else if (
        value &&
        (value == "invoice_fol_all" || value == "fixed_amount")
      ) {
        invoiceType = "remind_me_job_closed";
      }
      this.updateRecurringInvoiveSchedule(
        "recurringinvoice.invoice_type",
        invoiceType
      );
    },
    updateRecurringInvoiveSchedule(key, value) {
      this.$store.commit(UPDATE_INVOICE_SCHEDULE_RECURRING, {
        key: key,
        value: value,
      });
      this.generateInvoiceSchedule();
    },
    generateInvoiceSchedule() {
      /*  let invoiceArray = []; */
      let invoiceArrayPerPage = [];
      let invoiceArrayFixedAmount = [];
      let invoiceArrayForAll = [];
      let firstItem = [];

      if (this.recurringinvoice.invoice_main_type == "per_visit") {
        invoiceArrayPerPage = this.rawScheduleOutput.map((item) => ({
          daysOfWeek: item?.daysOfWeek,
          startDate: item?.startDate,
          dueDate: this.dueDate(item?.startDate),
          billing_amount: this.vCalculations.visit_amount,
          status: 1,
          isEdited: 0,
          isDisabled: 0,
          isPrimary: 0,
          uuid: item?.uuid,
        }));
        this.$store.commit(UPDATE_SCHEDULE_LIST, {
          key: "dbInvoiceVisitScheduleOutput",
          value: invoiceArrayPerPage,
        });
      }
      if (this.recurringinvoice.invoice_main_type == "fixed_amount") {
        firstItem = this.rawScheduleOutput[0];
        invoiceArrayFixedAmount = [
          {
            daysOfWeek: firstItem?.daysOfWeek,
            startDate: firstItem?.startDate,
            dueDate: this.dueDate(firstItem?.startDate),
            billing_amount: this.vCalculations?.visit_amount,
            status: 1,
            isEdited: 0,
            isDisabled: 0,
            isPrimary: 0,
            uuid: firstItem?.uuid,
          },
        ];
        this.$store.commit(UPDATE_SCHEDULE_LIST, {
          key: "dbInvoiceVisitScheduleOutput",
          value: invoiceArrayFixedAmount,
        });
      }
      if (this.recurringinvoice.invoice_main_type == "invoice_fol_all") {
        firstItem = this.rawScheduleOutput[0];
        invoiceArrayForAll = [
          {
            daysOfWeek: firstItem?.daysOfWeek,
            startDate: firstItem?.startDate,
            dueDate: this.dueDate(firstItem?.startDate),
            billing_amount:
              this.vCalculations?.visit_amount * this.rawScheduleOutput?.length,
            status: 1,
            isEdited: 0,
            isDisabled: 0,
            isPrimary: 0,
            uuid: firstItem?.uuid,
          },
        ];
        this.$store.commit(UPDATE_SCHEDULE_LIST, {
          key: "dbInvoiceVisitScheduleOutput",
          value: invoiceArrayForAll,
        });
      }
    },
    dueDate(date) {
      let day = 15;
      if (this.recurringinvoice?.payment_due == 2) {
        day = 15;
      } else if (this.recurringinvoice?.payment_due == 3) {
        day = 30;
      } else if (this.recurringinvoice?.payment_due == 4) {
        day = 45;
      }
      let dueData = moment(date).add(day, "days").format("YYYY-MM-DD");
      return dueData;
    },
  },
  components: {
    RadioInputCustom,
  },
  created() {
    this.visitId = Number(this.$route?.query?.visit ?? 0);
  },
  computed: {
    newArry() {
      if (this.recurringinvoice?.invoice_main_type === "per_visit") {
        return this.invoiceTypeListAll.filter(
          (item) =>
            item.value === "no_need_auto" ||
            item.value === "after_each_visit_complete" ||
            item.value === "remind_me_job_closed"
        );
      } else if (
        this.recurringinvoice?.invoice_main_type === "invoice_fol_all" ||
        this.recurringinvoice?.invoice_main_type === "fixed_amount"
      ) {
        return this.invoiceTypeListAll.filter(
          (item) =>
            item.value === "full_set_of_job_created" ||
            item.value === "full_set_of_job_completed" ||
            item.value === "remind_me_job_closed"
        );
      }
      return [];
    },
    ...mapGetters([
      "recurringinvoice",
      "invoiceVisitScheduleOutput",
      "rawScheduleOutput",
      "vCalculations",
    ]),
  },
};
</script>
