<template>
  <v-row>
    <v-col md="8">
      <v-row class="mx-0">
        <v-col md="12">
          <label class="mb-1" for="visit-one-off-start-date"
            >Is Invoice Needed</label
          >
          <v-select
            id="payment-mode"
            dense
            v-model="oneoffinvoice.invoice_needed"
            :items="neededInvoiceList"
            :disabled="visitId > 0 ? true : false"
            item-color="cyan"
            item-text="text"
            item-value="value"
            class="pl-0 mb-1"
            filled
            hide-details
            placeholder="Is Invoice Needed"
            solo
            flat
            color="cyan"
            @change="
              updateOneOffInvoiveSchedule(
                'oneoffinvoice.invoice_needed',
                $event
              )
            "
          />
        </v-col>
        <v-col md="4" v-if="oneoffinvoice.invoice_needed">
          <label class="mb-1" for="visit-one-off-start-date">Payment due</label>
          <v-select
            id="payment-mode"
            dense
            v-model="oneoffinvoice.payment_due"
            :items="paymentDueList"
            item-color="cyan"
            item-text="text"
            :disabled="visitId > 0 ? true : false"
            item-value="value"
            class="pl-0 mb-1"
            filled
            hide-details
            placeholder="Select Payment Due"
            solo
            flat
            color="cyan"
            @change="
              updateOneOffInvoiveSchedule('oneoffinvoice.payment_due', $event)
            "
          />
        </v-col>
        <v-col md="4" v-if="oneoffinvoice.invoice_needed">
          <label class="mb-1" for="visit-one-off-start-time"
            >Send Invoice To Customer</label
          >
          <v-select
            id="payment-mode"
            dense
            v-model="oneoffinvoice.send_invoice"
            :items="sendInvoiceList"
            item-color="cyan"
            item-text="text"
            item-value="value"
            :disabled="visitId > 0 ? true : false"
            class="pl-0 mb-1"
            filled
            hide-details
            placeholder="Select Payment Due"
            solo
            flat
            color="cyan"
            @change="
              updateOneOffInvoiveSchedule('oneoffinvoice.send_invoice', $event)
            "
          />
        </v-col>
        <v-col md="4" v-if="oneoffinvoice.invoice_needed">
          <label class="mb-1" for="visit-one-off-end-time"> Invoice Type</label>
          <v-select
            id="payment-mode"
            dense
            v-model="oneoffinvoice.invoice_type"
            :items="invoiceTypeList"
            item-color="cyan"
            item-text="text"
            item-value="value"
            class="pl-0 mb-1"
            filled
            hide-details
            placeholder="Select Invoice Type"
            :disabled="visitId > 0 ? true : false"
            solo
            flat
            color="cyan"
            @change="
              updateOneOffInvoiveSchedule('oneoffinvoice.invoice_type', $event)
            "
          />
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { UPDATE_INVOICE_SCHEDULE_ONE_OFF } from "@/core/services/store/invoice.module";
export default {
  name: "invoice-shedule",
  data() {
    return {
      paymentDueList: [
        { value: 2, text: "Net 15" },
        { value: 3, text: "Net 30" },
        { value: 4, text: "Net 45" },
      ],
      invoiceTypeList: [
        {
          value: "remind_me_job_closed",
          text: "Remind me to invoice when job is closed",
        },
        {
          value: "auto_create_on_job_creation",
          text: "Auto create invoice on job creation",
        },
        {
          value: "auto_create_on_job_completion",
          text: "Auto create invoice on job completion",
        },
      ],
      sendInvoiceList: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      neededInvoiceList: [
        { value: 0, text: "No" },
        { value: 1, text: "Yes" },
      ],
      visitId: 0,
    };
  },
  methods: {
    updateOneOffInvoiveSchedule(key, value) {
      this.$store.commit(UPDATE_INVOICE_SCHEDULE_ONE_OFF, {
        key: key,
        value: value,
      });
    },
  },
  computed: {
    ...mapGetters(["oneoffinvoice"]),
  },
  created() {
    this.visitId = Number(this.$route?.query?.visit ?? 0);
  },
};
</script>
